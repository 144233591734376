import React, {useEffect} from "react";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ImageModal from "components/shared/ImageModal";
import ProductTableInfo from "components/features/product/ProductTableInfo";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '1em 0'
    },
    divider: {
        marginLeft: 0
    },
    imgContainer: {
        flex: '1 1 0',
        width: '100%'
    },
    cover: {
        width: '100%',
        marginBottom: '1em'
        // [theme.breakpoints.up('md')]: {
        //     // width: '35%',
        //     // height: 'auto',
        // },
    },
    description: {
        margin: 'auto 1em'
    },
    title: {
        marginBottom: '1em',
        fontWeight: 700,
        backgroundColor: '#00b0e4ba',
        borderRadius: '1em 1em 0 0',
        padding: '0.2em',
        paddingLeft: '0.6em'
    },
    details: {
        display: 'flex',
        flexDirection: 'column-reverse',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            flexGrow: '1'
        },
    },
    priceLink: {
        color: '#b50000',
        fontWeight: 700
    },
    productFactTable: {
        flex: '1 1 0'
    }
}));

const products = [
    {
        title: 'Rubber O-rings',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black, Blue, Orange, Red & White',
            size: '4 - 110mm',
            material: 'Rubber',
            grade: 'Nitrile, EPDM & Silicon',
            shape: 'Customized'
        }
    },
    {
        title: 'Conduit Body Gasket',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black',
            size: '1/2 - 8 inches',
            material: 'Rubber',
            grade: 'Neoprene & Nitrile',
            shape: 'Customized'
        }
    },
    {
        title: 'Customised Rubber Washer',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black',
            size: 'Customized',
            material: 'Rubber',
            grade: 'Nitrile, EPDM & Natural',
            shape: 'Customized'
        }
    },
    {
        title: 'Silicon Rubber O-rings',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Multiple',
            size: '5 - 110mm',
            material: 'Rubber',
            grade: 'Silicon',
            shape: 'Round'
        }
    },
    {
        title: 'Sprinkler Rubber Ring',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black',
            size: '63mm & 75mm',
            material: 'Rubber',
            grade: 'Natural',
            shape: 'Round'
        }
    },
    {
        title: 'SWR Rubber Rings',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black, Blue & Yellow',
            size: '75mm & 110mm',
            material: 'Rubber & PP',
            grade: 'TPR & PP',
            shape: 'Round'
        }
    },
    {
        title: 'Pull Elbow Rubber Gasket',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black & Any',
            size: '1/2 - 8 inches',
            material: 'Rubber',
            grade: 'Neoprene & Nitrile',
            shape: 'Customized'
        }
    },
    {
        title: 'Anti Vibration Rubber Pads',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black, White & Any',
            size: 'Customized',
            material: 'Rubber',
            grade: 'Nitrile, EPDM & Natural',
            shape: 'Round, Square and Customized'
        }
    },
    {
        title: 'Drip Irrigation Grommet',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'White & Black',
            size: '5 - 32mm & Customized',
            material: 'Rubber',
            grade: 'Silicon, Nitrile, EPDM & Natural',
            shape: 'Customized'
        }
    },
    {
        title: 'Rubber Boots',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black',
            size: 'Customized',
            material: 'Rubber',
            grade: 'Nitrile, EPDM & Natural',
            shape: 'Customized'
        }
    },
    {
        title: 'Engine Rubber Gasket',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black & Any',
            size: 'Customized',
            material: 'Rubber',
            grade: 'Viton & Acrylic',
            shape: 'Customized'
        }
    },
    {
        title: 'Rubber Grommet',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black & Any',
            size: '5 - 32mm',
            material: 'Rubber',
            grade: 'Nitrile, EPDM & Natural',
            shape: 'Customized'
        }
    },
    {
        title: 'Rubber Masking',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black, Red & White',
            size: 'Customized',
            material: 'Rubber',
            grade: 'Silicon & Nitrile',
            shape: 'Customized'
        }
    },
    {
        title: 'Rubber Screw Caps',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black & Any',
            size: 'Customized',
            material: 'Rubber',
            grade: 'Nitrile, EPDM & Natural',
            shape: 'Customized'
        }
    },
    {
        title: 'Rubber Washers',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black & Any',
            size: '5 - 100mm',
            material: 'Rubber',
            grade: 'Nitrile, EPDM, Natural & Silicon',
            shape: 'Customized'
        }
    },
    {
        title: 'Silicon Rubber Gaskets',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'White, Red, Grey & Any',
            size: 'Customized',
            material: 'Rubber',
            grade: 'Silicon',
            shape: 'Round, Square & Customized'
        }
    },
    {
        title: 'Spark Plug',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black & Red',
            size: 'Standard',
            material: 'Rubber & PVC',
            grade: 'Silicon & PVC',
            shape: 'Standard'
        }
    },
    {
        title: 'Anti Vibration Mounting',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black, White & Any',
            size: 'Customized',
            material: 'Rubber',
            grade: 'Nitrile, EPDM & Natural',
            shape: 'Round, Square and Customized'
        }
    },
    {
        title: 'Calculator Keypad and Cover',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'White & Transparent',
            size: 'Customized',
            material: 'Rubber',
            grade: 'Silicon',
            shape: 'Customized'
        }
    },
    {
        title: 'Clamp Rubber Hose',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black & Any',
            size: 'Customized',
            material: 'Rubber',
            grade: 'Neoprene & EPDM',
            shape: 'Customized'
        }
    },
    {
        title: 'Dust Cover',
        images: ['/assets/images/product'],
        tableInfo: {
            color: 'Black & Any',
            size: 'Customized',
            material: 'Rubber',
            grade: 'Nitrile, EPDM & Natural',
            shape: 'Customized'
        }
    },

];

const Products = () => {
    const classNames = useStyles({});
    const location = useLocation();

    useEffect(() => {
        if(location.state && location.state.scrollToImage) {
            const element = document.getElementById(location.state.scrollToImage);
            window.scrollTo(0, window.pageYOffset + element.getBoundingClientRect().top)
        }
    }, []);

    return <Card className={classNames.root}>
        <div className={classNames.description}>
            <Typography variant="h5" component="h5">Description:</Typography>
            <Typography>
                Tech Rubber Industries offer a wide range of Rubber O-Rings, Rubber Gaskets, Rubber Washers, Rubber
                Seals, Customised Rubber Parts etc are manufactured with fine-grade raw material procured from
                authentic
                vendors. These products go through a pre-defined set of standard QC checks.
            </Typography>
        </div>
        <List>
            {
                products.map(product => {
                    return <>
                        <ListItem key={product.title} alignItems="flex-start">
                            <div>
                                <Typography id={product.title} className={classNames.title} variant="h5" component="h5">
                                    {product.title}
                                    <Typography>
                                        <Box component="span" fontWeight="fontWeightBold">Price: </Box> <a
                                        className={classNames.priceLink}
                                        href={`mailto:techrubber.info@gmail.com?Subject=Price Enquiry | Product Name: ${product.title}`}>Get
                                        Price</a>
                                    </Typography>
                                </Typography>
                                <span className={classNames.details}>
                                <Typography
                                    component="span"
                                    variant="body1"
                                    className={classNames.productFactTable}
                                    color="textPrimary"
                                >
                                    <ProductTableInfo data={product.tableInfo}/>
                                </Typography>
                                <div className={classNames.imgContainer}>
                                    {/*<Carousel items={[{*/}
                                    {/*    name: 'ABC',*/}
                                    {/*    image: `/assets/images/products/${product.title}/final1.jpg`*/}
                                    {/*},*/}
                                    {/*    {*/}
                                    {/*        name: 'ABC',*/}
                                    {/*        image: `/assets/images/products/${product.title}/final2.jpg`*/}
                                    {/*    }]}/>*/}
                                    <ImageModal
                                        className={classNames.cover}
                                        image={`/assets/images/product/${product.title}/final1.jpg`}
                                        alt={product.title}
                                    />
                                </div>
                            </span>
                            </div>
                        </ListItem>
                        <Divider className={classNames.divider} variant="inset" component="li"/>
                    </>
                })
            }
        </List>
    </Card>
};

export default Products;