import React from 'react';
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import ImageModal from "components/shared/ImageModal";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        margin: '2em auto',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            margin: 'auto',
        },
    },
    cover: {
        width: '30%',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    noMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    content: {
        padding: '0',
        paddingRight: '1em',
        [theme.breakpoints.down('sm')]: {
            padding: '1em 0'
        }
    }
}));

const CompanyDescription = () => {
    const classNames = useStyles({});

    return <Card className={classNames.root}>
        <CardContent className={classNames.content}>
            <Typography variant="body1" color="textPrimary" component="p">
                <Box component="span" fontWeight="fontWeightBold">Tech Rubber Industries</Box> was established in year
                2016, we are an eminent organization, engaged in
                manufacturing of Vulcanized Rubber Components. We offer a wide range of high quality products like
                O-rings, Rubber washers, Gaskets, Rubber seals etc. We use fine-grade raw material to manufacture the
                products, We deal with reliable vendors
                of the industry to procure fine-grade raw material. Moreover, providing customization
                facility for these products which helps in better engagement with our customers.
            </Typography>
            <br/>
            <Typography className={classNames.noMobile} variant="body1" color="textPrimary" component="p">
                We carry out our business operations efficiently. Our infrastructure is equipped with modern machinery
                and technology, which is operated by set of professionals. We manufacture products in bulk
                quantity as per the customer demands. In the whole process, we make sure the product quality & on time
                delivery were met with the customer requirement.
                <p>Tech Rubber Industries is Verified IndiaMart supplier.</p>
            </Typography>
        </CardContent>
        <ImageModal
            image="/assets/images/product/Silicon Rubber O-rings/final1.jpg"
            className={classNames.cover}
            alt="Company Image"
        />
    </Card>
};

export default CompanyDescription;