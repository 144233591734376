import React from "react";
import Typography from "@material-ui/core/Typography";
import {Field, Form} from 'react-final-form';
import {makeStyles, useTheme} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {orange} from "@material-ui/core/colors";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {sendContactUsMail} from "util/mailer";

const useStyles = makeStyles(theme => ({
    form: {
        // border: '1em solid #00000012',
        // padding: '1em'
        borderTop: '2px solid black',
        borderBottom: '2px solid black',
        boxShadow: '1px solid black'
    },
    header: {
        color: orange[500],
    },
    submit: {
        backgroundColor: orange[500],
        color: 'white',
        '&:hover': {
            backgroundColor: orange[800],
        }
    }
}));

const validate = values => {
    const errors = {};
    if (!values.fullName) {
        errors.fullName = 'Required';
    }
    if (!values.email) {
        errors.email = 'Required';
    }
    return errors;
};

const ContactUsForm = ({title}) => {
    const classNames = useStyles({});
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const onSubmit = e => {
        e.preventDefault();
        sendContactUsMail()
    };

    return <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({handleSubmit, submitting}) => (
            <form className={classNames.form} onSubmit={onSubmit} noValidate>
                <Paper style={{padding: 16}}>
                    <Typography className={classNames.header} variant="h6">Tell Us What Are You Looking For
                        ?</Typography>
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={smScreen ? 12 : 6}>
                            <Field
                                fullWidth
                                required
                                name="fullName"
                                component={TextField}
                                type="text"
                                label="Full Name"
                            />
                        </Grid>
                        <Grid item xs={smScreen ? 12 : 6}>
                            <Field
                                name="email"
                                fullWidth
                                required
                                component={TextField}
                                type="email"
                                label="Email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                fullWidth
                                name="comment"
                                component={TextField}
                                multiline
                                label="Comment"
                            />
                        </Grid>
                        <Grid item style={{marginTop: 16}}>
                            <Button
                                className={classNames.submit}
                                variant="contained"
                                type="submit"
                                disabled={submitting}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        )}
    />
};


export default ContactUsForm;