import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {ABOUT_US, CONTACT_US, INDEX, PRODUCTS} from "constants/pageUrls";
import BaseLayout from "components/router/BaseLayout";
import Home from "components/pages/Home";
import Products from "components/pages/Products";
import ContactUs from "components/pages/ContactUs";
import AboutUs from "components/pages/AboutUs";

const wrapRouteComponent = (Component, params = {}) => () => <BaseLayout><Component {...params}/></BaseLayout>;

const MainLayout = ({location}) => {
    return <Switch>
        <Route exact path={INDEX} component={wrapRouteComponent(Home)}/>
        <Route exact path={PRODUCTS} component={wrapRouteComponent(Products, {location})}/>
        <Route exact path={CONTACT_US} component={wrapRouteComponent(ContactUs)}/>
        <Route exact path={ABOUT_US} component={wrapRouteComponent(AboutUs)}/>
    </Switch>
};

export default MainLayout;