import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

const getModalStyle = () => {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
};

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '80%',
        maxWidth: '800px',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    img: {
        width: '100%'
    }
}));

const ImageModal = ({image, alt, className}) => {
    const classNames = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <img src={image} alt={alt} className={className} onClick={handleOpen}/>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <div style={modalStyle} className={classNames.paper}><img src={image} alt={alt} className={classNames.img}/></div>

            </Modal>
        </>
    );
};

export default ImageModal