import axios from 'axios';

export const sendContactUsMail = () => {
    console.log('Sending mail!...');
    axios.post('localhost:3000/send-mail', {
        fullName: 'Fred',
        email: 'test@mail.com',
        message: 'Hello World'
    },{
        mode: 'no-cors'
    });
};