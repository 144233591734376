import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Typography from "@material-ui/core/Typography";
import {orange} from "@material-ui/core/colors";
import {Paper} from "@material-ui/core";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        borderTop: '2px solid black',
        boxShadow: '1px solid black',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    header: {
        fontWeight: 500,
        margin: '1em',
        color: orange[500]
    },
    gridList: {
        width: '100%'
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));

const ImageGallery = ({ data, title }) => {
    const classesNames = useStyles();
    const history = useHistory();

    const handleClick = imageTitle => e => {
        e.preventDefault();
        history.push('/products', { scrollToImage: imageTitle })
    };

    return (
        <Paper className={classesNames.root}>
            <Typography className={classesNames.header} variant="h5">{title}</Typography>
            <GridList cellHeight={180} className={classesNames.gridList}>
                {data.map(tile => (
                    <GridListTile key={tile.img} onClick={handleClick(tile.title)}>
                        <img src={tile.img} alt={tile.title}/>
                        <GridListTileBar
                            title={tile.title}
                            actionIcon={
                                <IconButton aria-label={`info about ${tile.title}`} className={classesNames.icon}>
                                    <InfoIcon/>
                                </IconButton>
                            }
                        />
                    </GridListTile>
                ))}
            </GridList>
        </Paper>
    );
};

export default ImageGallery;
