import React from 'react'
import {Carousel as ResponsiveCarousel} from 'react-responsive-carousel';

const Carousel = ({items, showThumbs, className}) => {
    return <ResponsiveCarousel
        className={className}
        showArrows={true}
        showStatus={false}
        showThumbs={showThumbs}
        autoPlay={false}>
        {
            items.map((item) => {
                return <div><img key={item} src={item}/></div>
            })
        }
    </ResponsiveCarousel>
};

export default Carousel