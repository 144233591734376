import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import {orange} from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '1em'
    },
    header: {
        fontWeight: 500,
        marginBottom: '1em',
        color: orange[500]
    },
    details: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
    },
    contactButton: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '1em'
        },
    }
}));

const ContactUsDetails = () => {
    const classNames = useStyles({});

    return <div>
        <Paper className={classNames.root}>
            <Typography className={classNames.header} variant="h5">Contact Us</Typography>
            <div className={classNames.details}>
                <div>
                    <Typography>
                        <Box fontWeight="fontWeightBold">
                            Tech Rubber Industries
                        </Box>
                    </Typography>
                    <Typography variant="body1">Plot No. 531/32, Gali No 2, Basai, Near GreenWood Public
                        School</Typography>
                    <Typography variant="body1">Gurgaon - 122001, Haryana, India</Typography>
                    <Typography>
                        <Box component="span" fontWeight="fontWeightBold">Call us:</Box> 8744910006, 9654294531
                    </Typography>
                </div>
                <div className={classNames.contactButton}>
                    <Button href="mailto:techrubber.info@gmail.com?Subject=Contact Mail" variant="contained" color="primary">
                        Contact via Email
                    </Button>
                </div>
            </div>
        </Paper>
    </div>
};

export default ContactUsDetails