import React from "react";
import {makeStyles} from "@material-ui/core";
import {lightBlue} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '5em',
        width: '100%',
        backgroundColor: lightBlue[800],
        color: 'white'
    }
}));

const Footer = () => {
    const classNames = useStyles({});

    return <footer className={classNames.root}>
        <Typography component="p">
            © Tech Rubber Industries.
            All Rights Reserved (Terms of Use)</Typography>
        <Typography component="p">Developed and Managed by Cybersane</Typography>

    </footer>;
};

export default Footer;