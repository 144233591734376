import React from "react";
import GoogleMap from "components/shared/GoogleMap";
import ContactUsForm from "components/features/ContactUsForm";

const ContactUs = () => {
    return <>
        <GoogleMap
            pointerLocation="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1753.7708784989418!2d76.98821585716848!3d28.463226997276042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjjCsDI3JzQ3LjYiTiA3NsKwNTknMjAuNyJF!5e0!3m2!1sen!2sin!4v1575835199991!5m2!1sen!2sin dd"
        />
        <ContactUsForm title='Contact Us'/>
    </>
};

export default ContactUs