import React from 'react';
import {common, lightBlue, orange} from '@material-ui/core/colors';
import {fade, makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from "@material-ui/core/MenuItem";
import {ABOUT_US, CONTACT_US, INDEX, PRODUCTS} from "constants/pageUrls";
import {Link} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from "@material-ui/core/Drawer";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    navBar: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '5em',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: lightBlue[800],
        paddingLeft: '1em',
        paddingRight: '1em'
    },
    logo: {
        display: 'flex',
        color: orange[500],
        textDecoration: 'none'
    },
    logoImg: {
        borderRadius: '6%',
        marginRight: '1em'
    },
    logoSubtitle: {
        marginLeft: '1.8em'
    },
    navLink: {
        color: orange[500],
        textDecoration: 'none',
        [theme.breakpoints.up('sm')]: {
            color: common.white,
        }
    },
    drawerPaper: {
        width: '98%',
        marginRight: '1%'
    },
    desktopNav: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    mobileNav: {
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    contactButton: {
        color: orange[800],
        backgroundColor: 'transparent',
        border: '1px solid white',
        boxShadow: 'none',
        '&:hover': {
            color: 'white',
            backgroundColor: orange[800],
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
}));

const Header = () => {
    const classNames = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classNames.root}>
            <AppBar position="static" className={classNames.navBar}>
                <Link to="/" className={classNames.logo}>
                    <img className={classNames.logoImg} src="favicon_io/favicon.ico"/>
                    <Typography component="a" variant="h5">
                        Tech Rubber
                        <Typography variant="body1" className={classNames.logoSubtitle}>Industries</Typography>
                    </Typography>
                </Link>
                <div className={classNames.desktopNav}>
                    <Toolbar>
                        <Button className={classNames.contactButton}
                                href="mailto:techrubber.info@gmail.com?Subject=Contact Mail" variant="contained">
                            Email Us
                        </Button>
                        <MenuItem>
                            <Link className={classNames.navLink} to={INDEX}>Home</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link className={classNames.navLink} to={PRODUCTS}>Products</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link className={classNames.navLink} to={ABOUT_US}>About Us</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link className={classNames.navLink} to={CONTACT_US}>Contact Us</Link>
                        </MenuItem>
                    </Toolbar>
                    {/*<div className={classNames.search}>*/}
                    {/*    <div className={classNames.searchIcon}>*/}
                    {/*        /!*<SearchIcon />*!/*/}
                    {/*    </div>*/}
                    {/*    <InputBase*/}
                    {/*        placeholder="Search…"*/}
                    {/*        classes={{*/}
                    {/*            root: classNames.inputRoot,*/}
                    {/*            input: classNames.inputInput,*/}
                    {/*        }}*/}
                    {/*        inputProps={{ 'aria-label': 'search' }}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
                <div className={classNames.mobileNav}>
                    <Button className={classNames.contactButton}
                            href="mailto:techrubber.info@gmail.com?Subject=Contact Mail" variant="contained">
                        Email Us
                    </Button>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                    >
                        <MenuIcon/>
                    </IconButton>
                </div>
            </AppBar>
            <Drawer
                 variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classNames.drawerPaper,
                }}
            >
                <div>
                    <IconButton onClick={handleDrawerClose}>
                        {<ChevronRightIcon/>}
                    </IconButton>
                </div>
                <Divider/>


                <List>
                    <Link className={classNames.navLink} to={INDEX}>
                        <ListItem>
                            <ListItemText>
                                Home
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link className={classNames.navLink} to={PRODUCTS}>
                        <ListItem>
                            <ListItemText>
                                Products
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link className={classNames.navLink} to={ABOUT_US}>
                        <ListItem>
                            <ListItemText>
                                About Us
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link className={classNames.navLink} to={CONTACT_US}>
                        <ListItem>
                            <ListItemText>
                                Contact Us
                            </ListItemText>
                        </ListItem>
                    </Link>
                </List>
            </Drawer>
        </div>
    );
};

export default Header