import React from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import {makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    root: {
        width: '85%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    heading: {
        borderRight: '1px solid lightgrey',
        fontWeight: 'bold',
        width: '30%'
    }
}));

const ProductTableInfo = ({data}) => {
    const classNames = useStyles({});

    return (
        <Paper className={classNames.root}>
            <Table className={classNames.table} aria-label="simple table">
                <TableBody>
                    {Object.keys(data).map((key) => {
                        return <TableRow key={key}>
                            <TableCell className={classNames.heading}>{key.toUpperCase()}</TableCell>
                            <TableCell>{data[key]}</TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </Paper>
    )
};

export default ProductTableInfo