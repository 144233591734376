import React from 'react';
import AboutUs from "components/features/AboutUs";
import CompanyProfile from "components/features/CompanyProfile";

const AboutUsPage = () => {
    return <div>
        <AboutUs/>
        <CompanyProfile/>
    </div>
};

export default AboutUsPage