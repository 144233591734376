import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import 'typeface-roboto';
import 'scss/index.scss'
import MainLayout from "components/router/MainLayout";

ReactDOM.render(
    <BrowserRouter>
        <MainLayout/>
    </BrowserRouter>,
    document.getElementById('root')
);