import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import {orange} from "@material-ui/core/colors";
import CompanyDescription from "components/features/CompanyDescription";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '2em'
    },
    header: {
        fontWeight: 500,
        marginBottom: '1em',
        color: orange[500],
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0'
        },
    },
    aboutUsMain: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column-reverse'
        },
    }
}));


const facts = [
    {title: 'Nature of Business', value: 'Manufacturer', icon: BusinessCenterIcon},
    {title: 'Total Number of Employees', value: '11 to 25 People', icon: BusinessCenterIcon},
    {title: 'Year of Establishment', value: '2016', icon: BusinessCenterIcon},
    {title: 'GST No.', value: '06ACPPY2154E1ZD', icon: BusinessCenterIcon}
];

const AboutUs = () => {
    const classes = useStyles();
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Card className={classes.root}>
            <Typography className={classes.header} variant="h5">Welcome to Tech Rubber Industries</Typography>
            <div className={classes.aboutUsMain}>
                <Grid container spacing={3}>
                    {
                        facts.map(fact => {
                            const FactIcon = fact.icon;
                            return (
                                <Grid item xs={smScreen ? 12 : 4} className={classes.main}>
                                    <Grid container wrap="nowrap" spacing={2}>
                                        <Grid item>
                                            <FactIcon/>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="body1">{fact.title}</Typography>
                                            <Typography variant="caption">{fact.value}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <CompanyDescription/>
            </div>
        </Card>
    );
};

export default AboutUs