import React from 'react';
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '25em'
    },
    mapCanvas: {
        width: '100%',
        height: '100%',
        border: '0'
    }
}));

const GoogleMap = ({pointerLocation}) => {
    const classNames = useStyles({});

    return <div className={classNames.root}>
        <iframe
            className={classNames.mapCanvas}
            id="gmap_canvas"
            title="google map"
            src={pointerLocation}
        />
    </div>
};

export default GoogleMap