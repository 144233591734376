import React from "react";
import Carousel from "components/shared/Carousel";
import AboutUs from "components/features/AboutUs";
import ContactUsForm from "components/features/ContactUsForm";
import ContactUsDetails from "components/features/ContactUsDetails";
import {makeStyles} from "@material-ui/core";
import ImageGallery from "components/shared/ImageGallery";

const useStyles = makeStyles(theme => ({
    carouselContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'lightgrey'
    },
    carouselMain: {
        width: '640px'
    }
}));

const items = [
    // {
    //     name: 'Silicon Rubber O-rings',
    //     image: '/assets/images/products/Silicon Rubber O-rings/final1.jpg'
    // },
    // {
    //     name: 'Rubber O-rings',
    //     image: '/assets/images/products/Rubber O-rings/final1.jpg'
    // },
    // {
    //     name: 'Anti Vibration Rubber Pads',
    //     image: '/assets/images/products/Anti Vibration Rubber Pads/final1.jpg'
    // },
    // {
    //     name: 'SWR Rubber Rings',
    //     image: '/assets/images/products/SWR Rubber Rings/final1.jpg'
    // },
    // {
    //     name: 'Rubber Washers',
    //     image: '/assets/images/products/Rubber Washers/final1.jpg'
    // },
    // {
    //     name: 'Anti Vibration Mounting',
    //     image: '/assets/images/products/Anti Vibration Mounting/final1.jpg'
    // },
    // {
    //     name: 'Calculator Keypad and Cover',
    //     image: '/assets/images/products/Calculator Keypad and Cover/final1.jpg'
    // },
    // {
    //     name: 'Drip Irrigation Grommet',
    //     image: '/assets/images/products/Drip Irrigation Grommet/final1.jpg'
    // },
    // {
    //     name: 'Dust Cover',
    //     image: '/assets/images/products/Dust Cover/final1.jpg'
    // },
    '/assets/images/carousel/final5.jpg',
    '/assets/images/carousel/IMG_20191129_164724320-removebg-preview.jpg',
    // '/assets/images/carousel/IMG_20191129_173055320-removebg-preview.jpg',
    '/assets/images/carousel/IMG_20191129_173555660-removebg-preview.jpg',
    '/assets/images/carousel/IMG_20191129_180016722-removebg-preview.jpg',
    '/assets/images/carousel/IMG_20191129_180605517-removebg-preview.jpg',
    '/assets/images/carousel/IMG_20191129_181100926-removebg-preview.jpg',
    '/assets/images/carousel/IMG_20191129_181227325-removebg-preview (1).jpg',
    '/assets/images/carousel/IMG_20191129_181532052-removebg-preview.jpg',
    '/assets/images/carousel/IMG_20191129_181636108-removebg-preview.jpg',
    '/assets/images/carousel/IMG_20191129_182005294-removebg-preview.jpg',
    '/assets/images/carousel/IMG_20191129_183004273-removebg-preview.jpg',
    '/assets/images/carousel/IMG_20191129_185812449-removebg-preview.jpg',
    '/assets/images/carousel/IMG_20191129_185838214-removebg-preview.jpg',
    '/assets/images/carousel/IMG_20191129_190145701-removebg-preview.jpg',
    '/assets/images/carousel/IMG_20191129_190633986-removebg-preview.jpg'
];

const galleryData = [
    {img: `/assets/images/product/Conduit Body Gasket/final1.jpg`, title: 'Conduit Body Gasket'},
    {img: '/assets/images/product/Anti Vibration Rubber Pads/final1.jpg', title: 'Anti Vibration Rubber Pads'},
    {img: '/assets/images/product/Silicon Rubber O-rings/final1.jpg', title: 'Silicon Rubber O-rings'},
    {img: '/assets/images/product/Silicon Rubber Gaskets/final1.jpg', title: 'Silicon Rubber Gaskets'},
    {img: '/assets/images/product/Rubber O-rings/final1.jpg', title: 'Rubber O-rings'},
    {img: '/assets/images/product/Rubber Washers/final1.jpg', title: 'Rubber Washers'}
];


const Home = () => {
    const classNames = useStyles({});

    return <div>
        <div className={classNames.carouselContainer}><Carousel items={items} showThumbs={false}
                                                                className={classNames.carouselMain}/></div>
        <AboutUs/>
        <ImageGallery data={galleryData} title="Product Gallery"/>
        <ContactUsForm title="Tell Us What Are You Looking For ?"/>
        <ContactUsDetails/>
    </div>
};

export default Home;