import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '1em'
    },
    title: {
        marginBottom: '1em',
        fontWeight: 700,
        backgroundColor: '#00b0e4ba',
        borderRadius: '1em 1em 0 0',
        padding: '0.2em',
        paddingLeft: '0.6em'
    },
    tableTitle: {
        fontWeight: 700,
        backgroundColor: '#00b0e4ba',
        padding: '0.2em',
        paddingLeft: '0.6em'
    },
    heading: {
        borderRight: '1px solid black',
        fontWeight: 'bold',
        width: '30%'
    },
    tableSection: {
        marginBottom: '1em'
    }
}));

const companyProfileData = [
    {
        name: 'Basic Information', data: [
            {name: 'Nature of Business', value: 'Manufacturer'},
            {
                name: 'Registered Address',
                value: 'Plot No. 531/32, Gali No 2, Basai, Near GreenWood Public School, Gurgaon - 122001, Haryana, India'
            },
            {name: 'Industry', value: 'Manufacturing and Trading of Rubber Products and more.'},
            {name: 'Total Number of Employees', value: '11 to 25 People'},
            {name: 'Year of Establishment', value: '2016'}
        ]
    },
    {
        name: 'Team and Staff', data: [
            {name: 'No. of Research / QC Staff', value: '1 - 5 People'},
            {name: 'No. of Skilled Staff', value: '6 - 25 People'}
        ]
    },
    {
        name: 'Company USP', data: [
            {name: 'Sampling Policy', value: 'Paid'},
            {name: 'Primary Competitive Advantage', value: 'Good Financial Position & TQM and Large Product Line'},
            {name: 'Contract Manufacturing', value: 'Design Service Offered'},
            {name: 'Quality Measures / Testing Facilities', value: 'Yes'}
        ]
    },
    {
        name: 'Statutory Profile', data: [
            {name: 'GST No.', value: '06ACPPY2154E1ZD'},
        ]
    },
    {
        name: 'Packaging/Payment and Shipment Details', data: [
            {name: 'Customized Packaging', value: 'Yes'},
            {name: 'Estimated Time of Delivery', value: '5 to 15 Days'},
            {name: 'Payment Mode', value: 'Cheque, DD, NEFT and IMPS'},
        ]
    }
];

const CompanyProfile = () => {
    const classNames = useStyles({});

    return <div className={classNames.root}>
        <section>
            <Typography className={classNames.title} variant="h5" component="h5">
                Company Profile
            </Typography>
            {
                companyProfileData.map((section) => {
                    return <Paper className={classNames.tableSection}>
                        <Typography className={classNames.tableTitle} variant="h6" component="h5">
                            {section.name}
                        </Typography>
                        <Table aria-label="simple table">
                            <TableBody>
                                {
                                    section.data.map((tableField, index) => {
                                        return <TableRow key={index}>
                                            <TableCell
                                                className={classNames.heading}>{tableField.name.toUpperCase()}</TableCell>
                                            <TableCell>{tableField.value}</TableCell>
                                        </TableRow>

                                    })
                                }
                            </TableBody>
                        </Table>
                    </Paper>
                })
            }
        </section>
    </div>
};

export default CompanyProfile