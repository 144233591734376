import React from 'react';
import PropTypes from 'prop-types';
import Header from "components/features/Header";
import Footer from "components/features/Footer";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";

const propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	HeaderContent: PropTypes.func,
	hideFooter: PropTypes.bool,
	showLogo: PropTypes.bool,
};

const useStyles = makeStyles(theme => ({
	container: {
		[theme.breakpoints.down('sm')]: {
			padding: 0
		}
	}
}));

const BaseLayout = ({
	className,
	children,
	HeaderContent,
	showLogo,
	...restProps
}) => {
	const classNames = useStyles({});

	return <>
		<Container className={classNames.container}>
		<Header
			className='site-header'
			showLogo={showLogo}
		>
		</Header>
		<main id="main" className="main-container">
			{React.cloneElement(children, restProps)}
		</main>
		<Footer className='site-footer'/>
		</Container>
	</>
};

BaseLayout.propTypes = propTypes;

export default BaseLayout;
